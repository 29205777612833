<template>
  <div
    :id="receiverItemOps.peerId"
    :sId="sourceId"
    :class="[
      'receiverItem mediaSourceList',
      showSizeClass == 'm' ? 'sourceItemM' : '',
      showSizeClass == 'l' ? 'sourceItemL' : ''
    ]"
    :share-data="socketItemData.shareInfo || ''">
    <!--media框-->
    <span
      class="iconfont i-closeInput closeMediaIcon"
      style="top: 3px;"
      @click="toggleShareBox()"
      v-show="shareFlag && selectRIsLive"></span>
    <MediaRBox
      v-if="selectRIsLive && ifOpenMediaService && shareFlag"
      :shareFlag="shareFlag"
      :rId="receiverId"
      :sId="sourceId"
      :id="receiverId"
      :sType="socketItemData.type"
      :shareInfo="socketItemData.shareInfo" />
    <div
      :class="[
        'thumbnail-view-img pr',
        showSizeClass == 'm' ? 'imgMShow' : '',
        showSizeClass == 'l' ? 'imgLShow' : ''
      ]"
      :title="receiverItemOps.peerId">
      <volume-column
        v-if="socketItemData.status == 2"
        :rId="socketItemData.peerId || ''"
        :style="[
          showSizeClass == 'm' ? 'volumeMSize' : '',
          showSizeClass == 'l' ? 'volumeLSize' : ''
        ]"></volume-column>
      <device-detail
        :isOpen="isShow.deviceDetail"
        :rId="socketItemData.peerId || ''"
        v-if="socketItemData.status == 2"></device-detail>
      <div
        class="detail_btn"
        v-if="socketItemData.status == 2"
        :rId="socketItemData.peerId || ''">
        <device-detail-btn
          :isShowDeviceDetail="isShow.deviceDetail"
          :rId="socketItemData.peerId || ''"
          @changeShowDeviceDetail="changeShowDeviceDetail"></device-detail-btn>
      </div>
      <div
        v-if="socketItemData.status"
        class="defaultBgImg"
        @click="showVideo(receiverItemOps.peerId)">
        <img
          :src="freshImg"
          class="receiverImg"
          alt=""
          v-if="socketItemData.status == 2" />
        <img
          :src="onlineImg"
          class="receiverImg"
          alt=""
          v-if="socketItemData.status == 1" />
        <img
          :src="offlineImg"
          class="receiverImg offlineImg"
          alt=""
          v-if="socketItemData.status == 0" />
      </div>
    </div>
    <div class="receiverItemName">
      <!-- <i class="el-icon-collection-tag pointer f_left" @click='openBookmarkBox'></i> -->
      <i
        class="bookmark_icon pointer f_left"
        :class="['iconfont']"
        @click="operationBookmarkBox(true)">{{ socketItemData.userBookmarkDevice ? "&#xe63b;" : "&#xe636;" }}
      </i>
      <div class="favorite">
        <!--添加到收藏-->
        <i
          class="iconfont android-favorite-outline"
          v-show="!isFavorite"
          @click="addToFavorite()">&#xe6b9;</i>
        <i
          class="iconfont android-favorite"
          v-show="isFavorite"
          @click="addToFavorite()">&#xe6bb;</i>
      </div>
      <div
        class="left receiverInner"
        :title="socketItemData.name ? socketItemData.name : receiverItemOps.name">
        {{ socketItemData.name ? socketItemData.name : receiverItemOps.name }}
      </div>
      <b class="xiegang">/</b>
      <div class="enterRName">
        <div class="hide">{{ socketItemData }}</div>
        <el-input
          :placeholder="$t('lang.rinputDescrible')"
          @blur="editDescribes"
          @keyup.enter.native="editDescribesEnter"
          v-model="memo"
          @focus="memoFocus"
          @input="removeSpecialStr"
          ref="receiverMemo"></el-input>
      </div>
      <div
        class="right advanceIcon"
        :class="{'disabled': socketItemData.status == 0}">
        <span
          :title="$t('lang.AdvancedMore')"
          class="iconfont icon-ArtboardCopy"
          @click.stop="goToPage({dataMark: 'rc', rInfo: socketItemData})"></span>
      </div>
      <!--media相关-->
      <div
        class="right shareToggleIcon"
        v-show="selectRIsLive && ifOpenMediaService && mediaLoaded">
        <span
          class="iconfont i-share"
          :title="$t('lang.i18n_moreShare')"
          @click="toggleShareBox()">
        </span>
        <span
          class="sharingNum globalNum"
          v-show="socketItemData.shareSize && socketItemData.shareSize != 0">{{ socketItemData.shareSize }}</span>
      </div>
    </div>

    <div class="switchSourcePeer">
      <SourceSelect
        :receiverItemOps="receiverItemOps"
        :socketItemData="socketItemData"
        :socketRInfo="currentRInfo"
        @changeList="isSelectedNew" />
      <!--引入Take组件-->
      <videoButton
        :style="{
          'margin-left': '10px',
          float: showSizeClass == 'm' || 'l' ? 'right' : 'left',
          width: '73px'
        }"
        :sourceItem="socketItemData"
        :hadSelectDevice="isSelectNewR"
        :selectType="selectType"
        flag="R"
        :deviceList="deviceList"
        :socketRInfo="currentRInfo"
        :key="receiverItemOps.peerId"
        @changeHadSelectDevice="changeHadSelectDevice">
      </videoButton>
    </div>

    <!--显示live Status-->
    <div
      class="t_thumbnail_view_item_icon liveBg"
      v-if="socketItemData && socketItemData.status == 2">
      {{ $t("lang.home_Live") }}
    </div>
    <div
      class="t_thumbnail_view_item_icon liveOnHoldBg"
      v-if="socketItemData?.status == 1 && currentRInfo?.WorkMode == 1 && liveOnHoldFlag">
      {{ $t("lang.live_OnHold") }}
    </div>
    <div
      class="locationSet"
      :title="locationInfoCu.address ? locationInfoCu.address : ''"
      v-if="socketItemData.status == 2 && locationHref != '' && locationShow">
      <!--给设备添加位置显示-->
      <a
        @click="gotoMap(locationHref)"
        v-if="locationHref != '' && locationHref != 'NA'">
        <i class="iconfont">&#xe654;</i>
        {{ locationInfoCu.address }}
      </a>
      <span v-if="locationHref == 'NA'">
        <i class="iconfont">&#xe654;</i>{{ $t("lang.noAvailable") }}</span>
    </div>
    <!-- add bookmark -->
    <add-bookmark
      :visible="isShow"
      :lastBookmark="socketItemData.userBookmarkDevice || {}"
      :parentBookmark="socketItemData.parentBookmarkDevice || {}"
      :deviceObj="{ id: socketItemData.peerId, type: 'MyReceiver' }"
      @closeBox="operationBookmarkBox">
    </add-bookmark>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Tool from '@/assets/js/utils.js'

import videoButton from '@/components/VideoButton'
// import SelectSourceR from "@/components/SelectSourceR";
import SourceSelect from '@/components/sourceSelect'
import Bus from '@/assets/js/vueBus'
import { myReceiversAnalyze } from '@/assets/js/googleAnalyze/myReceiver.js'
import VolumeColumn from '@/components/VolumeColumn'
import DeviceDetail from '@/components/DeviceDetail'
import DeviceDetailBtn from '@/components/DeviceDetailBtn'
import MediaRBox from '@/components/mediaRbox'
import AddBookmark from '@/components/addBookmark'

export default {
  /*
    * receiverItemOps:通过Ajax传递过来的每条receiver的信息
    * socketItemDatas:通过websocket发送过来的每条receiver的socket动态信息
    * locationInfo需要传进来的经纬度信息
    */
  props: {
    receiverItemOps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    socketItemDatas: {
      type: Object,
      default: () => {
        return {}
      }
    },
    listKey: {
      type: Number,
      default: 0
    },
    locationInfo: {
      type: Array
    },
    imgUrlTime: Object,
    bookmarkObj: Object,
    parentBookmarkObj: Object,
    locationShow: {
      default: true,
      type: Boolean
    }
  },
  name: 'receiverItem',
  components: {
    videoButton,
    SourceSelect,
    VolumeColumn,
    DeviceDetail,
    DeviceDetailBtn,
    MediaRBox,
    AddBookmark
  },
  data () {
    return {
      currentReceiverId: this.receiverItemOps.peerId,
      logo: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"', // 如果图片报错使用默认图片,并解决图片闪烁问题
      deviceList: '', // 向Take按钮传送的值
      socketItemData: this.socketItemDatas, // 每一项的websocket中的值
      isFavorite: this.receiverItemOps.favorite == '1',
      enterName: this.$t('lang.rinputDescrible'),
      memo: this.receiverItemOps.describes, // 描述
      isCanFreshMemo: true, // 是否可以设置memo
      isShow: {
        deviceDetail: true,
        bookmark: false
      },
      isSelectNewR: false, // 是否重新选择了source
      isCanUpdateFavo: true, // 是否可以刷新favorite
      blobService: this.$store.state.common.baseUrl.blobUrl,
      deafultImg: require('@/assets/img/logo_thumbnail.png'),
      freshImg: '', // 要被刷新的图片的路径
      sItemId: this.receiverItemOps.livePeerId ? this.receiverItemOps.livePeerId.split(';')[0] : '', // 被选择的source的id
      onlineImg: require('@/assets/img/logo_thumbnail.png'),
      offlineImg: require('@/assets/img/offlinePng.png'),
      shareFlag: false,
      receiverId: this.socketItemDatas.peerId,
      sourceId: this.receiverItemOps.livePeerId ? this.receiverItemOps.livePeerId.split(';')[0] : '',
      selectRIsLive: this.socketItemDatas && this.socketItemDatas.status == '2',
      ifOpenMediaService: this.$store.state.common.baseUrl.mediaControl.myReceivers || false,
      mediaLoaded: false,
      locationName: '中国上海市长宁区虹桥路国峰科技大厦',
      locationHref: '',
      canSetMemo: true, // 防止重复点击
      locationInfoCu: {}, // 当前的位置信息
      searchId: [], // 次联框显示的数组
      showSource: [], // v-model的次联框的值
      showSelectInput: true, // 判断显示哪个输入框,
      searchSourceArr: [], // 搜索的时候显示的输入框的值
      showSourceInfo: '',
      loadingInput: false,
      updateFlag: true,
      arrFlag: true, // 用于搜索的数组操作
      parentLoad: true,
      selectType: ''
      // externalObj: null,
      // externalTag: '',
      // waitTime: true,
      // openWebRDirectly: false
    }
  },
  mounted () {
    document.addEventListener('visibilitychange', this.getPageHide)
    this.$once('hook:mounted', () => {
      document.removeEventListener('click', this.clickEvent, true)
      document.addEventListener('click', this.clickEvent, true)
    })
    if (this.receiverItemOps.status == 2) {
      this.showSourceInfo = this.receiverItemOps.livePeerName
    }
  },
  created () {
    if (this.receiverItemOps.status == 2) {
      // 第一次将live的source Id 和Rid拼接
      const rid = this.receiverItemOps.peerId ? this.receiverItemOps.peerId : ''
      this.deviceList = this.receiverItemOps.livePeerId + ',' + rid
    }
    if (this.ifOpenMediaService) {
      this.isCompleteLoad()
    }
    if (this.receiverItemOps.status == 2) {
      this.freshImg = window.location.protocol + '//' + this.blobService + '/' + this.receiverItemOps.peerId + '?random=' + Math.random()
    } else {
      this.freshImg = this.deafultImg
    }
    setInterval(() => {
      if (this.imgUrlTime.current[this.socketItemData.peerId] == this.imgUrlTime.last[this.socketItemData.peerId]) return
      if (this.socketItemData.status == 2) {
        // 不是在live的不能获取缩略图
        let blobUrl = ''
        const isCompressThumbnail = JSON.parse(localStorage.getItem('newCCInitConfig')) && JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo && JSON.parse(localStorage.getItem('newCCInitConfig')).urlInfo.isCompressThumbnail == '0'
        if (this.socketItemData.livePeerId) {
          // 缩略图:开始使用source的Id 10秒后使用R的id
          if (this.socketItemData.livePeerType == 'X') {
            blobUrl = window.location.protocol + '//' + this.blobService + '/' + this.socketItemData.livePeerId + '?'
            blobUrl = isCompressThumbnail ? `${blobUrl}type=original&app=cc&${Math.random()}` : `${blobUrl}random=${Math.random()}&app=cc`
          } else {
            blobUrl = window.location.protocol + '//' + this.blobService + '/' + this.socketItemData.peerId + '?'
            blobUrl = isCompressThumbnail ? `${blobUrl}type=original&app=cc&${Math.random()}` : `${blobUrl}random=${Math.random()}&app=cc`
          }
        } else {
          blobUrl = this.deafultImg
        }
        this.freshImg = blobUrl
      }
    }, 5000)
  },
  computed: {
    ...mapState({
      State: state => state,
      showSizeClass: state => state.common.showRecevierSize,
      liveOnHoldFlag: state => state.featureFlag.liveOnHoldFlag
    }),
    ...mapState('common', {
      States: state => state
    }),
    currentRInfo () {
      const rList = this.States.deviceListCurrentRInfoData
      let data = {}
      rList.map(v => {
        if (this.currentReceiverId == v.rid && Tool.isJSON(v.data)) {
          data = JSON.parse(v.data)
        }
      })
      // Live On Hold
      if (this.liveOnHoldFlag && this.socketItemData?.status == 1 && data?.WorkMode == 1 && !this.isSelectNewR) {
        this.deviceList = data.CurrentTStrIdHex.toLowerCase() + ',' + this.socketItemData.peerId
      }
      return data
    }
  },
  watch: {
    receiverItemOps: {
      handler (newVal) {
        this.memo = newVal.describes
      },
      deep: true
    },
    socketItemDatas (val, oldval) {
      val.userBookmarkDevice = this.bookmarkObj[val.peerId]
      val.parentBookmarkDevice = this.parentBookmarkObj[val.peerId]
      this.socketItemData = val
      if (val.livePeerId != this.sItemId) {
        // 说明livePeerId变化了
        this.sItemId = val.livePeerId
        this.sourceId = val.livePeerId
        const getSelectInfo = {
          rid: this.socketItemData.peerId,
          deviceList: this.sItemId + ',' + this.socketItemData.peerId
        }
        this.$emit('getSelectInfoContent', getSelectInfo) // 如果livePeerId变化了 将livepeerId 传递过去 供video使用
        if (this.updateFlag && val.status == 2) this.showSourceInfo = val.livePeerName
      }

      // 监听当前R状态，控制media的显示与隐藏
      this.selectRIsLive = val && val.livePeerId && val.livePeerId == this.sourceId

      if (!this.isSelectNewR) {
        // 如果没有重新选择
        if (val.status == 2) {
          // 如果正在live 将最新的R Id发送给Take组件
          this.deviceList = val.livePeerId + ',' + val.peerId
        }
      }
    },
    locationInfo (val) {
      if (val.length > 0) {
        val.map(item => {
          if (
            this.receiverItemOps.livePeerId &&
            item.peerId.toLowerCase().trim() == this.receiverItemOps.livePeerId.slice(0, 16).toLowerCase().trim()
          ) {
            if (typeof item.address != 'string') {
              let address = ''
              if (item.address) {
                item.address.map((itm, inx) => {
                  if (inx != item.address.length - 1) {
                    address += itm.long_name + ' , '
                  } else {
                    address += itm.long_name
                  }
                })
              }
              item.address = address
            }

            this.locationInfoCu = Object.assign({}, item)
            if (item.location && item.location.trim() != 'null,null') {
              const lat = item.location.split(',')[0]
              const lng = item.location.split(',')[1]
              this.locationHref = encodeURI(
                `${location.protocol}//${localStorage.getItem('newCCUrl')}/mapPage.html#?lat=${lat}&lng=${lng}&name=${this.receiverItemOps.name}`
              ) // 线上使用
            } else {
              this.locationHref = 'NA'
            }
          }
        })
      }
    }
    // externalObj (val, oldVal) {
    //   if (val && (!oldVal || oldVal && val.externalUrl && val.externalUrl != oldVal.externalUrl || oldVal && val.localUrl && val.localUrl != oldVal.localUrl)) {
    //     this.checkExternal()
    //   }
    // }
  },
  methods: {
    // async getPromptMsgCont () {
    //   await this.axios({
    //     method: 'post',
    //     url: '/ccp/tvucc-user/userDevice/listPreference',
    //     headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    //     data: {}
    //   }).then(res => {
    //     if (res.data.errorCode == '0x0') {
    //       if (res.data.result.length > 0) {
    //         const list = res.data.result
    //         list.map(v => {
    //           if (v.pn == 'openWebR') {
    //             if (v.content == 'false') {
    //               this.openWebRDirectly = false
    //             } else {
    //               this.openWebRDirectly = true
    //             }
    //           }
    //         })
    //       }
    //     }
    //   }).catch(() => {})
    // },
    // async checkExternal () {
    //   this.externalTag = ''
    //   if (this.externalObj && this.externalObj.externalUrl) {
    //     await this.axios.get(`/ccp/tvucc-user/base/can/access?url=${this.externalObj.externalUrl}`, {}, { timeout: 3000 }).then(async res => {
    //       if (res.data.errorCode == '0x0') {
    //         this.externalTag = 'externalUrl'
    //       }
    //     }).catch(() => {
    //     })
    //   }
    //   if (this.externalObj && this.externalObj.localUrl && this.externalTag == '') {
    //     await this.axios.get(`/ccp/tvucc-user/base/can/access?url=${this.externalObj.localUrl}`, {}, { timeout: 3000 }).then(res => {
    //       if (res.data.errorCode == '0x0') {
    //         this.externalTag = 'localUrl'
    //       }
    //     }).catch(() => {
    //     })
    //   }
    // },
    async goToPage (item) {
      if (item.rInfo.status == 0) return
      // const protocol = window.location.protocol
      // const ccUrl = localStorage.getItem('newCCUrl')
      // 'local.tvunetworks.com:8082'
      // localStorage.getItem('newCCUrl')
      this.$store.state.common.selectRcPeerId = item.rInfo.peerId
      this.$router.push({ path: `/${item.dataMark}` }).catch(() => {})
      // window.location.href = `${protocol}//${ccUrl}#/rc`
    },
    // async openWebRLink (item, tag) {
    //   if (item.status == 0 || this.waitTime) return
    //   const ccUrl = 'local.tvunetworks.com:8081'
    //   this.$store.state.common.baseUrl.switchUrl || localStorage.getItem('newCCUrl')
    //   const protocol = window.location.protocol
    //   const version = item.version && Number(item.version.slice(0, 3))
    //   let webRUrl = `${protocol}//${ccUrl}#/rc?peerId=${item.peerId}`
    //   // 当R>=7.9 或 R<=7.8且this.openWebRDirectly为true时
    //   if (version <= 7.8) await this.getPromptMsgCont()
    //   if (version >= 7.9 || this.openWebRDirectly) {
    //     let hasUrl = false
    //     // // 需要先判断是否有开放端口，如果有直接用，没有的情况下，7.9以上使用反连，7.9以下使用模拟界面
    //     if (this.externalTag && this.externalObj && this.externalObj[this.externalTag]) {
    //       webRUrl = this.externalObj[this.externalTag]
    //       hasUrl = true
    //     } else {
    //       if (version >= 7.9) {
    //         await this.axios.get(`/ccp/tvucc-device/device/setWebRProxyAddress?peerId=${item.peerId}`).then(res => {
    //           if (res.data.errorCode == '0x0') {
    //             webRUrl = `http://${res.data.result}`
    //             hasUrl = true
    //           }
    //         }).catch(() => {
    //         })
    //       } else {
    //         webRUrl = `${protocol}//${ccUrl}#/rc?peerId=${item.peerId}`
    //         hasUrl = true
    //       }
    //     }
    //     if (hasUrl) {
    //       if (version >= 7.9) {
    //         // 免密登录webR
    //         await this.axios.get('/ccp/tvucc-user/feature/get/webR/token', { params: { peerId: item.peerId, serviceName: 'R/#/' } }).then(res => {
    //           const result = res.data.result
    //           if (res.data.errorCode == '0x0' && typeof result == 'string') {
    //             webRUrl = `${webRUrl}/authapi/AuthorizeAppService?token=${result}`
    //           }
    //         }).catch(() => {})
    //       }
    //     } else {
    //       webRUrl = `${protocol}//${ccUrl}#/rc?peerId=${item.peerId}`
    //     }
    //   }
    //   window.open(webRUrl)
    // },
    isSelectedNew (obj) {
      this.isSelectNewR = obj.isSelectNewR
      this.deviceList = obj.deviceList
      this.selectType = obj.selectType
    },
    getPageHide (e) {
      if (e.target.visibilityState == 'visible') {
        this.showSelectInput = true
      }
    },
    // 打开或关闭bookmark box
    operationBookmarkBox (val) {
      this.isShow.bookmark = val
    },
    memoFocus () {
      myReceiversAnalyze('myReceiversMemoFocus', this.$route.path)
      this.isCanFreshMemo = false
    },
    isCompleteLoad () {
      if (typeof mediaObj != 'undefined') {
        this.mediaLoaded = true
      } else {
        setTimeout(() => {
          this.isCompleteLoad()
        }, 100)
      }
    },
    // 控制分享box的显示隐藏
    toggleShareBox () {
      if (this.socketItemData && this.socketItemData.status != '2') return
      this.shareFlag = !this.shareFlag
      if (this.shareFlag) {
        myReceiversAnalyze('myReceiversOpenShare', this.$route.path)
      } else {
        myReceiversAnalyze('myReceiversCloseShare', this.$route.path)
      }
      if ((!this.shareFlag) && (typeof ShareBox) === 'function' && (typeof ShareBox.close) === 'function') {
        ShareBox.close(this.receiverId)
      }
    },
    // 点击显示设备详情的btn
    changeShowDeviceDetail (val) {
      let event = 'Open'
      if (!val) event = 'Close'
      myReceiversAnalyze(`myReceiversVideoParameter${event}`, this.$route.path) // 谷歌统计 receiver延迟率窗口是否打开
      this.isShow.deviceDetail = val
    },
    changeHadSelectDevice (val) {
      /*
      * 获取是否重新选择了R
      * 此处接收两处地方的数据,一个是VideoButton是否操作完成的信息(start,stop)以便SelectSourceR组件来更新input里面的显示值
      * 一个是接收SelectSourceR里面的数据 以便VideoButton组件知道是否重新选择了R
      */
      this.isSelectNewR = val
    },
    showVideo (rId) {
      // 点击设备出现 下拉视频的前期准备 (获取响应的数据)
      if (this.socketItemData.status == 0) return false // 设备离线
      if (!rId) return false
      if (this.socketItemData == undefined) return false
      if (this.socketItemData && this.socketItemData.status && this.socketItemData.status == 0) return false
      const data = {
        key: this.listKey,
        currentReceiverData: this.socketItemData,
        sourceId: this.sItemId, // 用以做前后对比 以便判断是否显示播放组件
        rid: rId
      }
      this.$emit('showVideo', data) // 将播放的R id或者默认的id 和其他信息发送给播放组件
      Bus.$emit('restoreDefaultConfig')
      Bus.$emit('clearVideo')
    },
    getSelectInfo (data) {
      if (this.shareFlag) {
        this.sourceId = data.deviceList.split(',')[0]
      }
      this.deviceList = data.deviceList // 监听SelectSourceR.vue组件传递过来的参数
      this.sourceId = data.deviceList.split(',')[0]
      this.selectRIsLive = this.socketItemDatas && this.socketItemDatas.livePeerId != null && this.socketItemDatas.livePeerId == this.sourceId
      this.$emit('getSelectInfoContent', data) // 将选择的当前R向上传递到sourceContent中,
    },

    addToFavorite () {
      // 添加到收藏
      var params = {
        peerId: this.receiverItemOps.peerId
      }
      const paramFa = this.isFavorite ? '0' : '1'
      let event = 'Yes'
      if (this.favorite) {
        event = 'No'
      }
      this.isCanUpdateFavo = false
      myReceiversAnalyze(`myReceiversFavorite${event}`, this.$route.path) // 谷歌统计 receiver是否显示收藏
      params.favorite = paramFa
      this.axios.post('/ccp/tvucc-device/userDevice/favoriteOrLiveDescribe', params, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(response => {
        const data = response.data
        if (data.errorCode == '0x0') {
          this.isCanUpdateFavo = true // 可以更新favorite
          if (paramFa == '1') {
            this.isFavorite = true
            Tool.ToTip(
              null,
              'success',
              'lang.addFavoriteSuccess'
            )
          } else {
            this.isFavorite = false
          }
        } else if (data.errorCode == '0x80100005') {
          Tool.ToTip('0x80100005', 'warning')
          return false
        }
      }).catch(() => {
        this.isCanUpdateFavo = true
        Tool.ToTip(null, null)
        return false
      })
    },
    editDescribes () {
      // 编辑设备的描述
      if (!this.canSetMemo) return false
      this.canSetMemo = false
      myReceiversAnalyze('myReceiversMemoSave', this.$route.path)
      // 检测字符串长度是否超长
      if (this.memo.length > 12) {
        this.memo = this.memo.slice(0, 12)
      }
      var params = {
        peerId: this.receiverItemOps.peerId,
        describes: this.memo
      }
      this.axios.post('/ccp/tvucc-device/userDevice/favoriteOrLiveDescribe', params, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(response => {
        this.canSetMemo = true
        this.isCanFreshMemo = true
        if (response.data.errorCode == '0x0') {
        } else if (response.data.errorCode == '0x80100005') {
          Tool.ToTip(true, this.$t('lang.ParamError'))
          return false
        }
      }).catch(() => {
        this.canSetMemo = true
        this.isCanFreshMemo = true
      })
    },
    editDescribesEnter () {
      // 编辑设备的描述
      this.$refs.receiverMemo.blur() // NCC-1718
    },
    removeSpecialStr () {
      // 出去指定的特殊字符
      this.memo = this.memo.replace(/["'</=>%)(\s+\\]/g, '')
    },
    gotoMap (url) {
      // 跳转到map
      myReceiversAnalyze('myReceiverGotoMap', this.$route.path)
      window.open(url)
    },
    selectdSource () {
      let arr = [] // 可以选择的source所有信息数组
      this.searchId.map(v => {
        v.children.map(item => {
          arr.push(item)
        })
      })
      arr.map(i => {
        if (i.peerId == this.showSource[1]) {
          if (i.status == '0') {
            this.$message({
              type: 'warning',
              message: this.$t('lang.home_clickOfflineR')
            })
            this.isSelectNewR = false
          } else {
            this.isSelectNewR = true
            this.deviceList = i.peerId + ',' + this.socketItemData.peerId
          }
          this.showSourceInfo = i.name
        }
      })

      this.showSelectInput = true // 选择完让其隐藏
      myReceiversAnalyze('myRecevierselectSource', this.$route.path)
    },
    requestSourceList () {
      this.showSelectInput = false
      $('.el-cascader-menu__empty-text').html(
        this.$t('lang.i18n_loading')
      )
      let arr = []
      const url = '/ccp/tvucc-device/userDevice/querySourcelistFRByPack'
      const param = {
        peerId: this.receiverItemOps.peerId,
        condition: ''
      }

      this.axios.post(url, param, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        const data = res.data.result
        data.map((item, i) => {
          let obj = {}
          obj.label = Object.keys(data[i])[0]
          obj.value = Object.keys(data[i])[0]
          if (obj.label == 'TVU Anywhere') obj.label = 'TVUAnywhere'
          if (obj.label == 'TVU Pack') obj.label = 'TVUPack'
          if (obj.label == 'Grid') obj.label = 'TVU Grid'
          Object.values(data[i])[0].map(v => {
            v.label = v.name
            v.value = v.peerId
            if (v.status == '0') {
              v.disabled = true
            }
          })
          obj.children = Object.values(data[i])[0]
          arr.push(obj)
        })
        this.searchId = []
        if (arr.length == 0) {
          $('.el-cascader-menu__empty-text').html(
            this.$t('lang.NoResult')
          )
        }
        arr.map(item => {
          this.searchId.push(item)
        })
      }).catch(() => {})
    },
    searchSelect (i) {
      if (this.showSelectInput) {
        if (i) {
          this.searchApi('')
        }
      }
    },
    changIcon (i) {
      let str = ''
      if (i.label == 'External') {
        str = '&#xe725;'
      } else if (i.label == 'TVUAnywhere') {
        str = '&#xe766;'
      } else if (i.label == 'TVUPack') {
        str = '&#xe641;'
      } else if (i.label == 'TVU Grid') {
        str = '&#xe6d4;'
      }
      return str
    },
    backInputVal () {
      // console.log(this.showSource)
    },
    remoteMethod (query) {
      if (query != '') {
        myReceiversAnalyze('myRecevierSearchSource', this.$route.path)
        this.showSelectInput = true // 次级选择框隐藏
        this.searchApi(query)
      } else {
        this.searchSourceArr = []
        this.showSelectInput = false // 次级选择框显示
      }
    },
    searchApi (query) {
      const url = '/ccp/tvucc-device/userDevice/querySourcelistFRByPack'
      const param = {
        peerId: this.receiverItemOps.peerId,
        condition: query
      }
      this.axios.post(url, param, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        this.loadingInput = false
        this.searchSourceArr = []
        const data = res.data.result
        let onlineArr = []
        let liveArr = []
        let offLineArr = []
        data.map((item, i) => {
          let obj = {}
          obj.label = Object.keys(data[i])[0]
          obj.value = Object.keys(data[i])[0]
          if (obj.label == 'TVU Anywhere') obj.label = 'TVUAnywhere'
          if (obj.label == 'TVU Pack') obj.label = 'TVUPack'
          if (obj.label == 'Grid') obj.label = 'TVU Grid'
          Object.values(data[i])[0].map(v => {
            v.label = obj.label + '/' + v.name
            v.value = v.peerId
            if (v.status == '1' || v.status == '3') {
              onlineArr.push(v)
            }
            if (v.status == '2') {
              liveArr.push(v)
            }
            if (v.status == '0') {
              offLineArr.push(v)
            }
          })
        })
        this.searchSourceArr = this.searchSourceArr
          .concat(onlineArr)
          .concat(liveArr)
          .concat(offLineArr)
      }).catch(() => {})
    },
    clickEvent (e) {
      const tar = e.srcElement || e.target
      try {
        if (!this.$refs.sourceSearch.contains(tar)) {
          this.showSelectInput = true
        }
      } catch (error) { }
    },
    selectSearchList () {
      this.searchSourceArr.map(i => {
        if (i.peerId == this.showSourceInfo) {
          if (i.status == '0') {
            this.$message({
              type: 'warning',
              message: this.$t('lang.home_clickOfflineR')
            })
            this.isSelectNewR = false
          } else {
            if (this.receiverItemOps.status == 2) {
              if (this.receiverItemOps.livePeerId == i.peerId) {
                // 如果选择的是当前souce/R正在live设备 将this.$emit('changeHadSelectDevice', false) 置为false
                this.isSelectNewR = false
              } else {
                this.isSelectNewR = true
                this.deviceList = i.peerId + ',' + this.socketItemData.peerId
              }
            } else {
              this.isSelectNewR = true
              this.deviceList = i.peerId + ',' + this.socketItemData.peerId
            }
          }
          this.showSourceInfo = i.name
        }
      })
      myReceiversAnalyze('myRecevierselectSource', this.$route.path)
    },
    checkIsCheck (item) {
      // 判断当前是否是正在和当前设备live的设备
      if (this.socketItemData.status == 2) {
        const livePeerId = this.socketItemData.livePeerId
        if (livePeerId && livePeerId.toLowerCase() == item.peerId.toLowerCase()) {
          return 'show'
        }
      } else {
        return ''
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    // 销毁组件的时候 关闭监听 防止重复监听 浪费资源
    document.removeEventListener('click', this.clickEvent, true)
    next()
  },
  destroyed () {
    document.removeEventListener('visibilitychange', this.getPageHide)
  }
}
</script>

<style lang="less" scoped>
.red {
  color: red;
}

.show {
  opacity: 1 !important;
}

.check {
  display: inline-block;
  float: right;
  color: #39aa50;
  font-size: 18px;
  opacity: 0;
  font-weight: bold;
}

.white {
  color: white;
}

.brow {
  color: #777;
}

.locationSet {
  position: absolute;
  cursor: pointer;
  height: 25px;
  /*width: 178px;*/
  width: 100%;
  font-size: 0.1rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  line-height: 25px;
  z-index: 1;
  color: #fff;
  right: 0;
  top: 0;

  a {
    display: inline-block;
    width: 52%;
    height: 100%;
    margin-left: 48%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    /*background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, rgba(0, 0, 0, 0) 100%);*/
  }

  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  i {
    padding: 0 4px;
  }
}

.defaultBgImg {
  width: 100%;
  height: 100%;
  background: url('../../assets/img/logo_thumbnail.png') no-repeat center;
  background-size: 100%;
}

.defaultBgImg img {
  width: 100%;
  height: 100%;
}
.defaultBgImg .offlineImg {
  cursor: no-drop;
}
::v-deep .el-input .el-input__inner {
  border: none;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background: #424242 !important;
}

::v-deep .el-input.is-disabled:hover {
  border: none;
}

::v-deep .el-button--info.is-plain.is-disabled,
::v-deep .el-button--info.is-plain.is-disabled:hover,
::v-deep .el-button--info.is-plain.is-disabled:focus,
::v-deep .el-button--info.is-plain.is-disabled:active {
  background: #676767;
}

::v-deep .el-image {
  width: 100%;
  height: 100%;
}

.receiverItem {
  width: 364px;
  height: 292px;
  background: #08130a;
  position: relative;
  /*margin-right: 15px;*/
  margin: 0 auto;
  margin-bottom: 9px;
  /*float: left;*/

  .favorite {
    float: left;
    margin-right: 4px;

    i {
      font-size: 20px;
    }

    .android-favorite-outline {
      color: #ccc;
    }

    .android-favorite {
      color: #ff4d4f;
    }
  }

  .pairedImg {
    width: 16px;
    height: 16px;
    margin-top: 15.5px;
    margin-right: 5px;
    margin-left: 10px;
  }

  .left {
    float: left;
  }

  .thumbnail-view-img {
    height: 205px;
    width: 100%;
    cursor: pointer;
    border: none;
    overflow: hidden;
    img {
      width: 100% !important;
      border: none;
      z-index: 1;
    }

    .extTip {
      position: absolute;
      top: 14px;
      left: 48%;
      width: 40%;
      margin-left: -20%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: white;
      background: rgba(1, 1, 1, 0.4);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      border-radius: 4px;
      z-index: 2;
    }
  }

  .extBg {
    height: 205px;
    width: 100%;
    // background: url("../../assets/img/ext.jpg") no-repeat center;
    background: url('../../assets/img/External.png') no-repeat center;
    background-size: 100%;
  }

  .youtubeBg {
    height: 205px;
    width: 100%;
    // background: url("../../assets/img/youtube.jpg") no-repeat center;
    background: url('../../assets/img/YouTube_2.png') no-repeat center;
    background-size: 100%;
  }

  .packBg {
    height: 205px;
    width: 100%;
    background: url('../../assets/img/logo_thumbnail.png') no-repeat center;
    background-size: 100%;
  }

  .SMPTE2022Bg {
    height: 205px;
    width: 100%;
    background: url('../../assets/img/SMPTE.png') no-repeat center;
    background-size: 100%;
  }

  .t_thumbnail_view_item_icon {
    position: absolute;
    top: 0.16rem;
    left: 16px;
    padding: 0 8px;
    height: 28px;
    border: none;
    background: #1ec345;
    border-radius: 4px;
    line-height: 28px;
    text-align: center;
    &.liveOnHoldBg {
      background: #FF9A24;
      color: #fff;
    }
  }

  .receiverItemName {
    /*margin-left: 1%;*/
    height: 47px;
    text-align: left;
    padding: 0 10px;
    line-height: 47px;
    font-size: 12px;
    width: 99%;
    overflow: hidden;

    .el-icon-collection-tag {
      margin-top: 16px;
      margin-right: 10px;
      color: #dbdbdb;
    }

    .voipBox {
      width: 24px;
      height: 24px;
      margin-right: 13px;
    }

    .enterRName {
      float: left;
      width: 110px;

      ::v-deep .el-input__inner {
        background: transparent !important;
        color: #ccc;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #ccc;
      }
    }

    .shareToggleIcon {
      margin-right: 0!important;
      height: 48px;
      .i-share {
        height: 34px;
        line-height: 34px;
      }
    }
    .advanceIcon {
      height: 36px;
      .icon-ArtboardCopy {
        font-size: 18px;
      }
      &.disabled {
        color: #444;
        span {
          cursor: not-allowed;
        }
      }
    }
  }

  .sourceSearch {
    width: calc(100% - 100px);
    height: 32px;
    margin-left: 8px;
    z-index: 1;
    /*position: relative;*/

    ::v-deep input {
      border: none;
    }

    ::v-deep .el-select {
      width: 100%;
    }

    ::v-deep .el-input__inner {
      color: #33ab4f;
    }

    ::v-deep .el-input__inner::-webkit-input-placeholder {
      color: #8d8d8d;
    }

    ::v-deep .el-cascader-panel.is-bordered {
      border: none;
      margin-top: 3px;
      position: relative;
      z-index: 2075;

      .el-cascader-menu {
        background-color: #242424;
      }

      .el-cascader-node__label {
        display: flex;
        color: white;
      }
      .el-cascader-node {
        font-weight: normal;
      }
    }

    ::v-deep .el-cascader-node {
      padding: 0 5px 0 5px;
    }

    .scrollbar {
      position: absolute;
      top: 34px;
      left: 0;
      width: 100%;
      height: 212px;
      overflow-y: auto;
      background: #444;
      margin-bottom: 30px;
    }

    .scrollbar li {
      display: block;
      width: 100%;
      color: #fff;
      height: 26px;
      line-height: 26px;
      cursor: pointer;
      position: relative;
      text-indent: 40px;
      text-align: left;
    }

    .scrollbar li:hover {
      background: #455f45;
    }

    .scrollbar::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 7px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    .scrollbar::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background-color: rgba(144, 147, 153, 0.2);
      opacity: 0.7;
    }

    .scrollbar::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 10px;
      background: none;
    }

    ::v-deep .inputColorLive input {
      color: #33ab4f;
    }

    ::v-deep .inputColorOffline input {
      color: #676767 !important;
    }

    ::v-deep .inputColorOffline input::placeholder {
      color: #676767 !important;
    }

    ::v-deep .el-cascader {
      line-height: 32px;
      width: 100%;
    }

    ::v-deep .el-cascader-panel {
      height: 220px;

      .el-cascader-menu {
        height: 220px;
      }
    }

    ::v-deep .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }

  .switchSourcePeer {
    height: 30px;

    .sourveSearch {
      float: left;
    }
  }
}

.receiverInner {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 115px;
}

.xiegang {
  font-weight: 100;
  color: #666;
  float: left;
  font-size: 22px;
}

.sourceGreenBg {
  background: #39aa50 !important;
}

.sourceRedBg {
  background: #db1a14 !important;
}

.sourceGrayBg {
  background: #565656 !important;
}

.sourceTakeBtn {
  height: 30px;
  line-height: 8px !important;
  border: none !important;
  margin-left: 28px !important;
  background: #565656;
  color: #fff;
}

.liveBg {
  background: rgba(255, 51, 0, 1) !important;
  opacity: 0.76;
}

.offlineBg {
  background: rgba(153, 153, 153, 1) !important;
  opacity: 0.76;
}

.onlineBg {
  background: rgba(30, 195, 69, 1) !important;
  opacity: 0.76;
}

.red {
  color: #f00;
}

.gray {
  color: #999;
}

.ellipsis {
  width: 130px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.ellipsisInput {
  width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*text-align: left;*/
}

.volumeMSize {
  -moz-transform: scale(1.5, 1.5);
  -webkit-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
}

.volumeLSize {
  -moz-transform: scale(2, 2);
  -webkit-transform: scale(2, 2);
  -o-transform: scale(2, 2);
}
</style>
