<template>
  <div class="volumr_colume">
    <div
      class="audio_column"
      :class="{'voiclM':className == 'm','voiclL':className == 'l'}">
      <div class="audio_scale">
        <p
          class='audio_scale_num'
          v-for='(item, index) in columnNumArr'
          :key='index'>
          <span :class='item.className'>{{item.name}}</span>
        </p>
      </div>
      <div class="audio_col audio_col_one">
        <div class="volume_bar">
          <div
            class="progress-bar"
            :style="`width:${(Number(voiceInfo.LeftDb)+40)*(1.923)}%`"></div>
        </div>
        <div class="volume_bar">
          <div
            class="progress-bar"
            :style="`width:${(Number(voiceInfo.RightDb)+40)*(1.923)}%`"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    rId: {
      type: String,
      default: ''
    },
    size: {
      type: String
    }
  },
  data () {
    return {
      columnNumArr: [
        { name: '+12', className: 'scale_12' },
        { name: '', className: '' },
        { name: '', className: '' },
        { name: '0', className: 'scale_0' },
        { name: '', className: '' },
        { name: '', className: '' },
        { name: '-12', className: 'scale_minus_12' },
        { name: '', className: '' },
        { name: '-20', className: 'scale_minus_20' },
        { name: '', className: '' },
        { name: '', className: '' },
        { name: '', className: '' },
        { name: '', className: '' },
        { name: '-40', className: 'scale_minus_40' }
      ],
      voiceInfo: {},
      routerPath: ''
    }
  },
  computed: {
    listenVoiceData () {
      return this.$store.state.common.deviceListVolumeChartData
    },
    ...mapState({
      State: state => state,
      showSizeRecevier: state => state.common.showRecevierSize,
      showSizeMySource: state => state.common.showDeviceSize
    }),
    className () {
      if (this.routerPath.indexOf('mr') > 0) {
        return this.showSizeRecevier
      } else {
        return this.showSizeMySource
      }
    }
  },
  mounted () {
    // this.routerPath = window.location.pathname;
    this.routerPath = this.$route.path
  },
  watch: {
    listenVoiceData (val) {
      val.map((v, i) => {
        if (v.rid == this.rId && v && v.data) this.voiceInfo = JSON.parse(v.data)
      })
    },
    rId (newVal, oldVal) {
      if (newVal != oldVal) {
        this.voiceInfo = {}
      }
    }
  },
  methods: {
  }
}
</script>
<style lang='less' scoped>
.voiclM {
  transform: scale(1.4, 1.4);
  -webkit-transform: scale(1.4, 1.4);
  bottom: 40px !important;
}
.voiclL {
  transform: scale(2, 2);
  -webkit-transform: scale(2, 2);
  bottom: 100px !important;
}
.audio_column {
  position: absolute;
  bottom: 21px;
  left: 0;
  height: 128px;
  width: 47px;
  padding-left: 36px;
  .audio_scale {
    height: 100%;
    width: 2px;
    background-color: #999;
  }
  .audio_scale_num {
    width: 3px;
    height: 2px;
    background-color: #999;
    margin-left: -2px;
    margin-bottom: 7.7px;
    font-size: 12px;
    position: relative;
    span {
      position: absolute;
      top: -4px;
      left: -22px;
    }
    .scale_0 {
      left: -11px;
      top: -7px;
    }
    .scale_minus_12 {
      left: -20px;
      top: -8px;
    }
    .scale_minus_20 {
      left: -19.5px;
      top: -7px;
    }
    .scale_minus_40 {
      left: -20px;
      top: -12px;
    }
  }
  .audio_col {
    position: absolute;
    bottom: 62px;
    left: -26px;
    transform: rotate(-90deg);
    height: 4px;
    width: 128px;
    margin-left: 4px;
  }
  .volume_bar {
    background-color: #666;
    height: 3px;
    margin-top: 2px;
  }
  .volume_bar:first-child {
    margin-top: 0;
  }
  .progress-bar {
    background-color: #33ab4f;
  }
}
</style>
