<template>
  <el-dialog
    :title="$t(`lang.Bookmark`)"
    :visible.sync="visible.bookmark"
    width="600px"
    :center="true"
    :close-on-click-modal="false"
    class="deivce_add_bookmark"
    :before-close="handleClose">
    <el-form
      ref="form"
      label-position="top"
      label-width="100%">
      <ul class='select_bookmark_list ellipsis'>
        <li
          class='ellipsis'
          v-for='(item, index) in bookmarkArr'
          :key='index'>
          <span
            class='ellipsis'
            :class="{'gray': item.disabled}">{{item.name}}</span>
          <i
            v-if="!item.disabled"
            class="el-icon-circle-close"
            @click='cancelBookmark(item)'></i>
        </li>
      </ul>
      <el-form-item label="">
        <el-radio-group
          v-model="addType"
          @change='changeType'>
          <el-radio label="0">{{$t('lang.addTo')}}</el-radio>
          <el-radio label="1">{{$t('lang.addNewBookmark')}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label="$t('lang.includeInBookmark')"
        v-show='addType==0'>
        <el-cascader
          v-if='isShow.cascader'
          :key="iscascaderShow"
          placeholder=""
          v-model='selectBookmark'
          :options="bookmarkList"
          :props="props"
          :collapse-tags='true'
          expandTrigger='hover'
          class='select_bookmark_box'
          popper-class='bookmark_bookmark_list'
          @change='changeSelectNode'>
        </el-cascader>
      </el-form-item>
      <el-form-item
        :label="$t('lang.bookmarkName')"
        v-show='addType==1'>
        <el-input
          v-model="bookmarkName"
          maxlength='32'></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('lang.includeInBookmark')"
        v-show='addType==1'>
        <!-- placeholder="Bookmarks Bar" -->
        <el-cascader
          :key="iscascaderShow"
          :placeholder="$t('lang.bookmarksBar')"
          popper-class='bookmark_bookmark_list bookmark_parent_list'
          v-model="bookmarkParent"
          expandTrigger='hover'
          :options="bookmarkList"
          :props="{ checkStrictly: true, label: 'bookmarkName' }"
          @change="changeParent">
        </el-cascader>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        @click="handleClose"
        class="cancel">{{$t('lang.cancel')}}</el-button>
      <el-button
        type="primary"
        class="save"
        v-repeat-click='{event:save}'>{{$t('lang.Save')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import toTips from '@/assets/js/toTipsObj'
import Bus from '@/assets/js/vueBus.js'
import { newHomeAnalyze } from '@/assets/js/googleAnalyze/newHome.js'
import { mySourceAnalyze } from '@/assets/js/googleAnalyze/mySource.js'
export default {
  props: {
    visible: Object,
    deviceObj: Object,
    lastBookmark: {
      type: Object,
      default: () => {
        return {}
      }
    },
    parentBookmark: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      bookmarkArr: [],
      addType: null,
      props: { multiple: true, checkStrictly: true, label: 'bookmarkName' },
      bookmarkList: [],
      bookmarkName: '',
      selectBookmark: [],
      bookmarkParent: '',
      isShow: {
        cascader: true
      },
      lastBookmarkIds: [],
      parentId: '',
      iscascaderShow: 0
    }
  },
  watch: {
    async 'visible.bookmark' (val) {
      if (!val) {
        this.initData()
        return
      }
      if (val) {
        await this.getAllBookmark()
        this.lastBookmarkIds = (this.lastBookmark && Object.keys(this.lastBookmark)) || [] // 上次保存的书签
        this.selectBookmark = []
        this.lastBookmarkIds.map(v => {
          const arr = [`${this.lastBookmark[v][0].id}-${this.lastBookmark[v][0].bookmarkName}`]
          if (this.lastBookmark[v][0].parent.length) {
            this.getParent(this.lastBookmark[v][0].parent[0], arr)
          }
          this.selectBookmark.push(arr)
        })
        this.changeSelectNode(this.selectBookmark)
        if (this.accountType == 4 && this.parentBookmark != '{}') {
          Object.keys(this.parentBookmark).forEach(key => {
            const str = this.parentBookmark[key][0].bookmarkName
            const obj = {
              id: [this.parentBookmark[key][0].id + '-' + str],
              name: str,
              disabled: true
            }
            if (this.parentBookmark[key][0].parent.length > 0) {
              this.addParentSelectBookmark(this.parentBookmark[key][0].parent, obj)
            } else {
              this.bookmarkArr.push(obj)
            }
          })
        }
      }
    }
  },
  computed: {
    accountType () {
      return this.$store.state.common.baseUserInfo.role
    }
  },
  methods: {
    // 获取父级id和name
    getParent (parent, arr) {
      arr.unshift(`${parent.id}-${parent.bookmarkName}`)
      if (parent.parent.length) {
        this.getParent(parent.parent[0], arr)
      }
    },
    // 初始化数据
    initData () {
      this.bookmarkParent = ''
      this.selectBookmark = []
      this.bookmarkList = []
      ++this.iscascaderShow
      this.addType = null
      this.bookmarkName = ''
    },
    // 关闭弹窗
    handleClose () {
      // this.$emit('update:dialogVisible', false)
      // this.visible.bookmark = false
      this.initData()
      this.$emit('closeBox', false)
      if (this.$route.path.indexOf('nhm') > -1) {
        newHomeAnalyze('newHomeTableCloseBookmark', this.$route.path)
      } else {
        mySourceAnalyze('mySourceTableCloseBookmark', this.$route.path)
      }
    },
    // 获取bookmark列表
    async getAllBookmark () {
      await this.axios.get('/ccp/tvucc-user/bookmark/getAllChildBookmarkTree').then(res => {
        if (res.data.errorCode != '0x0') {
          this.$message.error()
          return
        }
        this.bookmarkList = res.data.result
        ++this.iscascaderShow
        this.recursionBookmark(res.data.result)
      }).catch(() => {})
    },
    // 递归遍历数组
    recursionBookmark (arr) {
      arr.map(v => {
        v.value = `${v.id}-${v.bookmarkName}`
        // v.label = v.bookmarkName
        if (!v.children.length) delete v.children
        if (v.children) {
          this.recursionBookmark(v.children)
        }
      })
    },
    // 更改选项
    changeType (val) {
      if (val != 0) return
      const node = document.querySelector('.select_bookmark_box .el-cascader__tags .el-tag.el-tag--info span')
      if (!node || !this.bookmarkArr.length) return
      node.innerText = this.bookmarkArr[0].name
    },
    addParentSelectBookmark (val, obj) {
      val.map(v => {
        obj.id.push(v.id + '-' + v.bookmarkName)
        obj.name = `${v.bookmarkName}/${obj.name}`
        if (v.parent.length > 0) {
          this.addParentSelectBookmark(v.parent, obj)
        } else {
          this.bookmarkArr.push(obj)
        }
      })
    },
    // 更改选中的节点
    changeSelectNode (val) {
      this.bookmarkArr = []
      val.map(v => {
        let name = ''
        v.map(item => {
          const idx = item.indexOf('-')
          name += `${item.slice(idx + 1, item.length)}/`
        })
        const obj = {
          name: name.slice(0, name.length - 1),
          id: v
        }
        this.bookmarkArr.push(obj)
      })
      setTimeout(() => {
        const node = document.querySelector('.select_bookmark_box .el-cascader__tags .el-tag.el-tag--info span')
        if (!node || !this.bookmarkArr.length) return
        node.innerText = this.bookmarkArr[0].name
      }, 200)
      console.log(this.bookmarkArr)
    },
    // 取消选中的bookmark
    cancelBookmark (item) {
      const id = item.id.join('')
      for (let i = 0; i < this.selectBookmark.length; i++) {
        if (this.selectBookmark[i].join('') == id) {
          this.selectBookmark.splice(i, 1) // 删除多级联动中的数据
          break
        }
      }
      for (let i = 0; i < this.bookmarkArr.length; i++) {
        if (this.bookmarkArr[i].name == item.name) {
          this.bookmarkArr.splice(i, 1)
          break
        }
      }
      // 更新view层
      const newBookmarkArr = this.selectBookmark
      this.bookmarkArr = JSON.parse(JSON.stringify(this.bookmarkArr))
      this.selectBookmark = []
      if (!newBookmarkArr.length) { // 解决element的cascader不能手动清空最后项的问题
        this.isShow.cascader = false
      }
      this.$nextTick(() => {
        this.selectBookmark = newBookmarkArr
        this.isShow.cascader = true
      })
    },
    // 更改父级的bookmark
    changeParent () {
      this.parentId = this.bookmarkParent[this.bookmarkParent.length - 1].split('-')[0]
    },
    // 保存数据
    save () {
      if (this.$route.path.indexOf('nhm') > -1) {
        newHomeAnalyze('newHomeTableBookmarkSave', this.$route.path)
      } else {
        mySourceAnalyze('mySourceTableBookmarkSave', this.$route.path)
      }
      const addBookmarkIds = []
      const removeBookmarkArr = []
      const selectBookmarkArr = []
      this.selectBookmark.map(v => {
        const bookmarkId = v[v.length - 1].split('-')[0]
        selectBookmarkArr.push(bookmarkId)
        if (!this.lastBookmarkIds.includes(bookmarkId)) { // 如果当前选中的之前有，不用重复添加
          addBookmarkIds.push(bookmarkId)
        }
      })
      this.lastBookmarkIds.map(v => {
        if (!selectBookmarkArr.includes(v)) { // 当前的bookmark id没有包含之前的,remove
          removeBookmarkArr.push(v)
        }
      })
      if (this.addType == 1) { // add to a new bookmark被激活
        if (!removeBookmarkArr.length && !addBookmarkIds.length && !this.bookmarkName.replace(/\s+/g, '')) {
          this.$message.error(this.$t('lang.bookmarkNameRequired'))
          return
        }
      } else { // add to被激活
        if (!removeBookmarkArr.length && !addBookmarkIds.length && !this.bookmarkName.replace(/\s+/g, '')) {
          if (!this.lastBookmarkIds.length) {
            this.$message.error(this.$t('lang.selectBookmark'))
          } else {
            this.handleClose()
          }
          return
        }
      }
      const params = {
        bookmarkIds: addBookmarkIds.join(','),
        addPeerId: this.deviceObj.id, // device id
        removeBookmarkIds: removeBookmarkArr.join(','),
        bookmarkPid: this.bookmarkName.trim() ? this.parentId || '0' : null,
        name: this.bookmarkName.replace(/\s+/g, '')
      }
      this.axios.post('/ccp/tvucc-user/bookmarkDevice/correlateDeviceBookmark', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          if (res.data.errorCode != '0x0') {
            const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
            return
          }
          this.$message.success(this.$t('lang.success'))
          Bus.$emit(`update${this.deviceObj.type}`)
          this.handleClose()
        }).catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
.deivce_add_bookmark {
  color: #dfdfdf;
  ::v-deep
    .el-dialog
    .el-dialog__header
    .el-dialog__headerbtn
    .el-icon-close:before {
    content: '\E78D';
  }
  ::v-deep .el-radio,
  ::v-deep .el-form-item__label {
    color: #dfdfdf;
    line-height: 19px;
    padding-bottom: 0;
  }
  ::v-deep .el-form-item {
    margin-bottom: 16px;
  }
  ::v-deep .el-dialog .el-dialog__body .el-input__inner {
    line-height: 40px;
    height: 40px;
  }
  ::v-deep .el-form-item__content,
  ::v-deep .el-cascader {
    width: 100%;
  }
  ::v-deep .el-icon-arrow-down:before {
    content: '\E60B';
  }
  .select_bookmark_list {
    max-height: 132px;
    overflow-y: scroll;
    font-size: 14px;
    li {
      margin-bottom: 13px;
      span {
        padding-right: 12px;
        display: inline-block;
        max-width: calc(100% - 30px);
        &.gray {
          color: #666;
        }
      }
      i {
        display: none;
        font-size: 12px;
        color: #444;
        vertical-align: top;
        padding-top: 5px;
      }
    }
    li:hover i {
      display: inline-block;
    }
  }
}
</style>
<style lang="less">
.bookmark_bookmark_list {
  z-index: 3000 !important;
  border: none;
  .el-cascader-panel .el-cascader-menu {
    background: #444;
  }
  .el-icon-arrow-right,
  .el-cascader-node__label {
    color: #dfdfdf;
  }
  ::v-deep .el-cascader-node.in-active-path,
  ::v-deep .el-cascader-node.is-active,
  ::v-deep .el-cascader-node.is-selectable.in-checked-path {
    background: #455f45;
  }
}
.bookmark_parent_list {
  .el-cascader-panel
    .el-scrollbar.el-cascader-menu:nth-child(3)
    .el-icon-arrow-right,
  .el-cascader-panel .el-scrollbar.el-cascader-menu:nth-child(4) {
    display: none;
  }
}
</style>
