<template>
  <div class="content">
    <ReceiverSearch></ReceiverSearch>
    <div
      class="listBox"
      v-loading='loading'
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <!--引入receiver的组件-->
      <ReceiverContent
        :queryRlist="queryRlist"
        :bookmarkObj='bookmarkObj'
        :parentBookmarkObj='parentBookmarkObj'
        v-if="showReceiver"
        :socketObj="socketObj"
        :imgUrlTime='imgUrlTime'></ReceiverContent>
    </div>
  </div>
</template>

<script>
import ReceiverContent from '@/views/MyReceiver/ReceiverContent.vue'
import ReceiverSearch from './ReceiverSearch'
import Bus from '@/assets/js/vueBus.js'
import { mapState, mapMutations } from 'vuex'

import { addMediaJs, handleRlist } from '@/assets/js/shareCommon.js'

export default {
  name: 'ReceiverIndex',
  components: {
    ReceiverContent,
    ReceiverSearch
  },
  data () {
    return {
      loading: true,
      queryRlist: [],
      peerIds: '', // 所有的Rid
      socketObj: [],
      pageMsgWebsocket: {},
      liveRids: [],
      onlineLiveRids: [],
      shareData: [],
      ifOpenMediaService: this.$store.state.common.baseUrl.mediaControl.myReceivers || false,
      initCurrentInfoSocket: true,
      initReceiverInfoSocket: true,
      showReceiver: false,
      imgTimer: null,
      imgUrlTime: {
        current: {},
        last: {}
      },
      bookmarkObj: {},
      parentBookmarkObj: {}
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket,
      liveOnHoldFlag: state => state.featureFlag.liveOnHoldFlag
    })
  },
  created () {
    this.loading = true
    Bus.$on('isLoading', data => {
      this.loading = data
      if (this.loading) {
        this.showReceiver = false
      } else {
        this.showReceiver = true
      }
    })
    Bus.$on('resultLIst', data => {
      this.queryRlist = []
      this.queryRlist = Object.assign([], data)
      this.showReceiver = true
      this.bookmarkObj = {}
      this.parentBookmarkObj = {}
      let tempArr = []
      const peerArr = []
      data.map((item, index) => {
        item.lastLiveName = ''
        tempArr[item.peerId] = item
        this.bookmarkObj[item.peerId] = item.userBookmarkDevice
        this.parentBookmarkObj[item.peerId] = item.parentBookmarkDevice || {}
        peerArr.push(item.peerId)
      })

      this.queryRlist = Object.assign([], data)
      // 处理记录上一次Live的Source信息
      if (peerArr.length) this.getLastLiveDevice(peerArr)

      this.$nextTick(() => {
        this.socketObj = tempArr
      })
      // this.socketObj = tempArr;
      if (data.length > 0) {
        this.sendWebsocket(data)
        if (this.imgTimer) {
          clearInterval(this.imgTimer)
        }
        this.imgTimer = setInterval(() => {
          this.thumbnailUpdate()
        }, 5000)
      }
    })
    if (this.ifOpenMediaService) {
      addMediaJs('R', 'myReceivers', (shareData) => {
        this.shareData = shareData
      })
    }
  },
  watch: {
    onlineLiveRids (val) {
      if (val.length > 0) { // 开启websocket
        if (this.initReceiverInfoSocket) {
          this.sendMsgWebsocket('ReceiverData', '2152857600', '101', 1000)
          this.updateSocketParams('ReceiverData', '2152857600', '101')
          // Live on hold
          if (this.liveOnHoldFlag) {
            this.sendMsgWebsocket('CurrentRInfo', '2152857600', '102', 1000)
            this.updateSocketParams('CurrentRInfo', '2152857600', '102')
          }
          this.initReceiverInfoSocket = false
        }
      } else { // 关闭websocket
        this.$createWebsocket.pageRWebsocket('stop', ['currentRInfo'])
        this.initReceiverInfoSocket = true
      }
    },
    liveRids (val) { // 监听receiverlist的变化 用以停止currentRInfo 的websocket 防止数据获取不到
      if (val.length > 0) { // 开启websocket
        if (this.initCurrentInfoSocket) {
          this.createMsgWebsocket()
          this.updateParams()
          this.initCurrentInfoSocket = false
        }
      } else { // 关闭websocket
        this.initCurrentInfoSocket = true
        this.stopupdateParams()
      }
    }
  },
  methods: {
    ...mapMutations('common', [
      'DEVICELIST_VOLUMECHARTDATA',
      'DEVICELIST_SLOTINFODATA',
      'DEVICELIST_RECEIVERDATADATA',
      'DEVICELIST_CURRENTRINFODATA',
      'DEVICELIST_SETINFODATA'
    ]),
    // 创建websocket
    createMsgWebsocket () {
      this.sendMsgWebsocket('VolumeChart', '2152867840', '200', 200) // 获取音量信息
      this.sendMsgWebsocket('SlotInfo', '2152865792', '101', 10000) // 获取slot信息
      if (!this.liveOnHoldFlag) this.sendMsgWebsocket('CurrentRInfo', '2152857600', '102', 1000) // 获取err and quality信息
      this.sendMsgWebsocket('SetInfo', '2152866048', '101', 1000) // 获取err and quality信息
    },
    // 获取设备实时信息
    sendMsgWebsocket (type, categoryId, operationType, time) {
      let typeVal = ''
      if (type != 'VolumeChart') typeVal = `${type[0].toLowerCase()}${type.slice(1, type.length)}`
      this.pageRWebsocket && this.pageRWebsocket.send({
        message: {
          categoryId: categoryId,
          operationType: operationType,
          rid: typeVal == 'receiverData' || typeVal == 'currentRInfo' && this.liveOnHoldFlag ? this.onlineLiveRids.join() : this.liveRids.join()
        },
        callBackPass: 'rInfo', // 用以回调函数的正确接收
        time: time || 1000,
        key: typeVal || type,
        success: res => {
          this.updateDeviceDetailData(`deviceList${type}Data`, `DEVICELIST_${type.toUpperCase()}DATA`, res)
        }
      })
    },
    // 更新音柱和设备详情信息
    updateDeviceDetailData (deviceData, fn, data) {
      let deviceDetailArr = []
      let deviceDetailIdArr = []
      this.$store.state.common[deviceData].map((v, i) => {
        if (this.onlineLiveRids.includes(v.rid)) {
          deviceDetailArr.push(v)
          deviceDetailIdArr.push(v.rid)
        }
      })
      if (deviceDetailIdArr.includes(data.rid)) {
        deviceDetailArr[deviceDetailIdArr.indexOf(data.rid)] = data
      } else {
        deviceDetailArr.push(data)
      }
      this[fn](deviceDetailArr)
    },
    // 更新websocket参数
    updateParams () {
      if (this.liveRids.length > 0) {
        this.updateSocketParams('VolumeChart', '2152867840', '200')
        this.updateSocketParams('SlotInfo', '2152865792', '101')
        this.updateSocketParams('CurrentRInfo', '2152857600', '102')
        this.updateSocketParams('SetInfo', '2152866048', '101')
      }
    },
    stopupdateParams () {
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', ['VolumeChart', 'slotInfo', 'setInfo']) // 停止websocket
      }
    },
    // 更新websocket参数
    updateSocketParams (type, categoryId, operationType) {
      if (type != 'VolumeChart') type = `${type[0].toLowerCase()}${type.slice(1, type.length)}`
      const message = {
        categoryId: categoryId,
        operationType: operationType,
        rid: type == 'receiverData' || type == 'currentRInfo' && this.liveOnHoldFlag ? this.onlineLiveRids.join() : this.liveRids.join()
      }
      if (this.pageRWebsocket && this.pageRWebsocket.sendMsgs && this.pageRWebsocket.sendMsgs[type] && this.pageRWebsocket.sendMsgs[type].message) {
        this.pageRWebsocket.sendMsgs[type].message = message
      }
      if (this.pageRWebsocket && this.pageRWebsocket.sendMsgs && this.pageRWebsocket.sendMsgs[type]) {
        this.pageRWebsocket.sendMsgs[type].callBackPass = 'rInfo'
      }
    },
    // 更新缩略图
    thumbnailUpdate () {
      let peerIds = []
      this.peerIds.map(v => {
        if (v) peerIds.push(v)
      })
      if (!peerIds.length) return
      this.axios.post(`${location.protocol}//${this.$store.state.common.baseUrl.blobUrl.split('/')[0]}/thumbnailVersion`, peerIds, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          this.imgUrlTime.last = this.imgUrlTime.current
          this.imgUrlTime.current = res.data
        }).catch(() => {})
    },
    sendWebsocket (data) {
      var sendParam = []
      this.peerIds = []
      if (data) {
        data.forEach(item => {
          sendParam.push(item.peerId)
        })
      }
      this.peerIds = sendParam
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'taskInfo_R_W') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
      }
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'taskInfo',
          module: 'taskInfo_R_W',
          data: { peerIds: this.peerIds.join(',') }
        },
        time: 1000,
        key: 'taskInfo_R_W',
        success: res => {
          var results = res.result
          this.liveRids = []
          this.onlineRids = []
          if (results.length > 0) {
            var thisD = []
            results.map(item => {
              // live的rid
              if (item.status == 2 && item.type.toLowerCase() == 'r') this.liveRids.push(item.peerId)
              if (item.status != 0 && item.type.toLowerCase() == 'r') this.onlineLiveRids.push(item.peerId)
              // 处理记录最后一次Live的R信息
              if (item.status == 1 && item.type.toLowerCase() == 'r') {
                const index = this.queryRlist.findIndex(n => n.peerId == item.peerId)
                if (index >= 0) item.lastLiveName = this.queryRlist[index].lastLiveName
              }
              thisD[item.peerId] = item
            })

            // 更新websocket参数
            this.updateParams()
            this.socketObj = this.ifOpenMediaService && this.shareData.RInfo && this.shareData.RInfo.length > 0 ? handleRlist(this.shareData, thisD, 'R') : thisD
          }
          let newParam = []
          if (this.queryRlist) {
            this.queryRlist.forEach(item => {
              newParam.push(item.peerId)
            })
          }
          this.peerIds = newParam
          var taskInfoMsg = {
            type: 'taskInfo',
            module: 'taskInfo_R_W',
            data: { peerIds: this.peerIds.join(',') }
          }
          this.pageRequest.sendMsgs.taskInfo_R_W.message = taskInfoMsg
        }
      })
    },
    getLastLiveDevice (arr) {
      this.axios.post('/ccp/tvucc-device/device/getDeviceLastLivePidMsg', arr, {
        contentType: 'application/json;charset=UTF-8',
        isCancelToken: true
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          if (res.data.result.length) {
            const resultArr = res.data.result
            resultArr.forEach(m => {
              const index = this.queryRlist.findIndex(n => n.peerId == m.peerId)
              if (index >= 0 && m.livePid) {
                this.queryRlist[index].lastLiveName = m.peerName
              }
            })
          }
        }
      }).catch(() => {})
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.ifOpenMediaService && window.SM) {
      SM.__proto__.stopSocket()
    }
    next()
  },
  beforeDestroy () {
    if (this.imgTimer) clearInterval(this.imgTimer)
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'taskInfo_R_W') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
    }
    if (this.$createWebsocket.pageRWebsocket) {
      this.$createWebsocket.pageRWebsocket('stop', ['VolumeChart', 'slotInfo', 'receiverData', 'currentRInfo', 'setInfo']) // 停止websocket
    }
  }
}
</script>

<style scoped lang="less">
.content {
  width: 99%;
  padding-left: 0.5%;
  height: 100% !important;
  max-width: 1920px;
  margin: 0 auto;

  .listBox {
    height: calc(100% - 94px);
  }

  .dataEmpty {
    width: 100%;
    height: 100%;
    text-align: center;

    .empty {
      line-height: 60px;
      color: #666;
      font-size: 16px;
      position: fixed;
      left: 50%;
      top: 39%;
      transform: translateX(-50%);
    }
  }
}

@media (max-width: 1919px) {
  .listBox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
