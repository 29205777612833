<template>
  <div
    class="device_detail"
    :class='{show_detail:isOpen}'>
    <div>
      <div class="type clearfix">
        <p class='f_left'>{{$t(`lang.type`)}}:&nbsp;</p>
        <p class='f_left'>{{formatInfo.CurrentTInputType && formatInfo.CurrentTInputType.ReadableName}}</p>
      </div>
      <div class="type clearfix">
        <p class='f_left'>{{$t(`lang.inputFor`)}}:&nbsp;</p>
        <p class='f_left'>{{formatInfo.CurrentTFormat && formatInfo.CurrentTFormat.ReadableName}}</p>
      </div>
      <div class="type clearfix">
        <p class='f_left'>{{$t(`lang.outputFor`)}}:&nbsp;</p>
        <p class='f_left'>{{formatInfo.CurrentOutputFormat2 && formatInfo.CurrentOutputFormat2.ReadableName}}</p>
      </div>
      <div class="type clearfix errorInfo">
        <p class='f_left'>{{$t(`lang.bitrate`)}}:&nbsp;</p>
        <p class='f_left'>
          <span :class="showBitClass">{{receiverInfo.QualityMeasureOnGUI}}</span> / {{bitDelayInfo.CurrentSetting.Bitrate}} kbps</p>
        <div class="hide">{{receiverInfo}}</div>
      </div>
      <div class="type clearfix">
        <p class='f_left'>{{$t(`lang.delay`)}}:&nbsp;</p>
        <p class='f_left'>{{getDelay((bitDelayInfo.CurrentSetting.Delay/1000).toFixed(1), bitDelayInfo.CurrentTCanSetDelay)}}</p>
      </div>
      <div class="type clearfix errorInfo">
        <p class='f_left'>{{$t(`lang.error`)}}:&nbsp;</p>
        <p class='f_left'>
          <span :class="showErrorColor">{{Math.round(qualityErrInfo.ErrorRateLastSec)}}%</span>
        </p>
        <div class="hide">{{qualityErrInfo.ErrorRateLastSec}}</div>
      </div>
      <div class="type clearfix">
        <p class='f_left'>{{$t(`lang.quality`)}}:&nbsp;</p>
        <p class='f_left'>{{Math.round(qualityErrInfo.ReSendRateLastSec)}}%</p>
      </div>
    </div>
    <div class="slot">
      <ul
        class="card-info row"
        v-show="slotInfo && slotInfo.AdapterModels">
        <li
          class=""
          v-if="slotInfo.AdapterModels[0]">
          <i :class='{one:slotInfo.AdapterModels[0].Status == 1,two:slotInfo.AdapterModels[0].Status == 2,three:slotInfo.AdapterModels[0].Status == 3}'></i>
        </li>
        <li
          class=""
          v-if="slotInfo.AdapterModels[1]">
          <i :class='{one:slotInfo.AdapterModels[1].Status == 1,two:slotInfo.AdapterModels[1].Status == 2,three:slotInfo.AdapterModels[1].Status == 3}'></i>
        </li>
        <li
          class=""
          v-if="slotInfo.AdapterModels[2]">
          <i :class='{one:slotInfo.AdapterModels[2].Status == 1,two:slotInfo.AdapterModels[2].Status == 2,three:slotInfo.AdapterModels[2].Status == 3}'></i>
        </li>
        <li
          class=""
          v-if="slotInfo.AdapterModels[3]">
          <i :class='{one:slotInfo.AdapterModels[3].Status == 1,two:slotInfo.AdapterModels[3].Status == 2,three:slotInfo.AdapterModels[3].Status == 3}'></i>
        </li>
        <li
          class=""
          v-if="slotInfo.AdapterModels[4]">
          <i :class='{one:slotInfo.AdapterModels[4].Status == 1,two:slotInfo.AdapterModels[4].Status == 2,three:slotInfo.AdapterModels[4].Status == 3}'></i>
        </li>
        <li
          class=""
          v-if="slotInfo.AdapterModels[5]">
          <i :class='{one:slotInfo.AdapterModels[5].Status == 1,two:slotInfo.AdapterModels[5].Status == 2,three:slotInfo.AdapterModels[5].Status == 3}'></i>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Tool from '@/assets/js/utils.js'

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: true
    },
    rId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      slotInfo: {
        AdapterModels: [
          { Status: 0 },
          { Status: 0 },
          { Status: 0 },
          { Status: 0 },
          { Status: 0 },
          { Status: 0 }
        ]
      },
      qualityErrInfo: {
        ErrorRateLastSec: 0,
        ReSendRateLastSec: 0
      },
      formatInfo: {
        CurrentTInputType: { ReadableName: '' },
        CurrentTFormat: { ReadableName: '' }
      },
      bitDelayInfo: {
        CurrentSetting: {
          Bitrate: 0,
          Delay: 0
        }
      },
      errorTime: 0 // use it to show different font color
    }
  },
  filters: {
  },
  computed: {
    ...mapState('common', {
      States: state => state
    }),
    // slot
    listenSlotData () {
      return this.$store.state.common.deviceListSlotInfoData
    },
    // quality and error
    listenQualityErrData () {
      return this.$store.state.common.deviceListReceiverDataData
    },
    // format and type
    listenFormatData () {
      return this.$store.state.common.deviceListCurrentRInfoData
    },
    // bitrate and delay
    listenBitDelayData () {
      return this.$store.state.common.deviceListSetInfoData
    },
    receiverInfo () {
      let data = {}
      const rList = this.States.deviceListReceiverDataData
      rList.map(v => {
        const currentRid = v.rid
        if (this.rId == currentRid && Tool.isJSON(v.data)) {
          data = JSON.parse(v.data)
        }
      })
      return data
    },
    showBitClass () {
      let _str = ''
      const realBit = this.receiverInfo.QualityMeasureOnGUI
      const setBit = this.bitDelayInfo.CurrentSetting.Bitrate
      if ((realBit / setBit) < 0.6) {
        _str = 'orange'
      }
      if ((realBit / setBit) < 0.4) {
        _str = 'flashRed'
      }
      return _str
    },
    showErrorColor () {
      let _str = ''
      const errorInfo = this.qualityErrInfo.ErrorRateLastSec
      const currentTime = new Date().getTime()
      if (errorInfo) {
        if (this.errorTime === 0) {
          this.errorTime = currentTime
        }
        if (currentTime - this.errorTime <= 5000) {
          _str = 'flashRed'
        }
        if (currentTime - this.errorTime > 5000) {
          _str = 'orange'
        }
      } else {
        this.errorTime = 0
      }
      return _str
    }
  },
  watch: {
    // slot
    listenSlotData (val) {
      val.map((v, i) => {
        // if (v.rid == this.rId) this.slotInfo = v;
        if (v.rid == this.rId && Tool.isJSON(v.data)) this.slotInfo = JSON.parse(v.data)
      })
    },
    // quality and error
    listenQualityErrData (val) {
      val.map((v, i) => {
        // if (v.deviceId == this.rId) this.qualityErrInfo = v;
        if (v.rid == this.rId && Tool.isJSON(v.data)) this.qualityErrInfo = JSON.parse(v.data)
      })
    },
    // format and type
    listenFormatData (val) {
      val.map((v, i) => {
        if (v.rid == this.rId && Tool.isJSON(v.data)) this.formatInfo = JSON.parse(v.data)
      })
    },
    // bitrate and delay
    listenBitDelayData (val) {
      val.map((v, i) => {
        if (v.rid == this.rId && Tool.isJSON(v.data)) this.bitDelayInfo = JSON.parse(v.data)
      })
    },
    // rid
    rId (newVal, oldVal) {
      if (newVal != oldVal) {
        this.initData()
      }
    }
  },
  methods: {
    // 获取delay
    getDelay (value, setFlag) {
      if (!setFlag) {
        const val = this.$t('lang.Dynamic')
        return val
      }
      return `${value} s`
    },
    // 初始化值
    initData () {
      this.slotInfo = {
        AdapterModels: [
          { Status: 0 },
          { Status: 0 },
          { Status: 0 },
          { Status: 0 },
          { Status: 0 },
          { Status: 0 }
        ]
      }
      this.qualityErrInfo = {
        ErrorRateLastSec: 0,
        ReSendRateLastSec: 0
      }
      this.formatInfo = {
        CurrentTInputType: { ReadableName: '' },
        CurrentOutputFormat2: { ReadableName: '' }
      }
      this.bitDelayInfo = {
        CurrentSetting: {
          Bitrate: 0,
          Delay: 0
        }
      }
    }
  }
}
</script>
<style lang='less' scoped>
.device_detail {
  position: absolute;
  cursor: pointer;
  height: 175px;
  width: 190px;
  padding: 5px 0 5px 10px;
  font-size: 0.6rem;
  background: rgba(0, 0, 0, 0.32);
  z-index: 1;
  transition: all 0.6s ease;
  color: #fff;
  bottom: -175px;
  right: 0;
  .type {
    line-height: 12px;
    font-size: 12px;
    p {
      margin-bottom: 8px;
    }
    p:first-child {
      width: 38%;
      text-align: left;
    }
  }
  .slot {
    .card-info {
      width: 56%;
      height: 30px;
      margin: 0;
      padding: 0;
      position: absolute;
      bottom: 0;
      left: 10px;
    }
    li {
      display: block;
      width: 27px;
      float: left;
      text-align: center;
      margin-bottom: 6px;
    }
    i {
      width: 9px;
      height: 9px;
      display: block;
      border-radius: 100%;
      background: #666;
    }
    i.one {
      background: #ff3300;
    }
    i.two {
      background: rgb(255, 144, 0);
    }
    i.three {
      background: #33ab4f;
    }
  }
  .bitInfo {
    p {
      span {
        opacity: 1;
        transition: opacity 1s;
      }
    }
  }
  .orange {
    color: #ff9000;
  }
  .flashRed {
    color: #ff0000;
    -webkit-animation: twinkling 0.5s infinite ease-in-out;
  }
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes twinkling {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes twinkling {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.device_detail.show_detail {
  bottom: 0;
}
</style>
