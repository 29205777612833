<template>
  <div
    class="device_detail_btn"
    @click.stop='showDeviceDetail'
    :class='{bg:!isShowDeviceDetail}'>
    <!-- <div class="detail_btn" @click.stop='showDeviceDetail' :class='{bg:!isShow.deviceDetail}'> -->
    <p v-show='isShowDeviceDetail'>
      <i class="el-icon-triangle-down"></i>
      <i class="el-icon-triangle-down"></i>
    </p>
    <p v-show='!isShowDeviceDetail'>
      <i class="el-icon-triangle-up"></i>
      <i class="el-icon-triangle-up"></i>
    </p>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  props: {
    isShowDeviceDetail: {
      type: Boolean,
      default: true
    },
    rId: {
      type: String,
      default: ''
    }
  },
  methods: {
    showDeviceDetail () {
      this.$emit('changeShowDeviceDetail', !this.isShowDeviceDetail)
    }
  }
}
</script>
<style lang='less' scoped>
.device_detail_btn {
  position: absolute;
  right: 6px;
  bottom: 0;
  width: 28px;
  height: 28px;
  z-index: 1;
  background: rgba(0, 0, 0, 0);
  transition: all 0.6s ease;
  i {
    color: rgba(204, 204, 204, 1);
    font-size: 16px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 2px;
    font-weight: 700;
  }
  i:nth-child(2) {
    top: 10px;
  }
}
.device_detail_btn.bg {
  background: rgba(0, 0, 0, 0.32);
}
</style>
